import React from "react";
import PropTypes from "prop-types";

import Layout from "./components/layout";
import SEO from "./components/seo";
import PropertyList from "./components/property-list";
import PropertySearch from "./components/property-search";
import Properties from "../../common/Properties";
import Paginator from "../../common/Paginator";
import OpenStreetMap from "../../common/OpenStreetMap";
import { templateVariableValue } from "../../helpers/templateVariables";
import { translateStatic } from "../../helpers/translationHelper";

import * as theme3Styles from "../theme3/scss/theme3.module.scss";

// import downArrow from "./images/down-arrow-color.png";

class Theme3PropertiesMap extends Properties {
  constructor(props) {
    super(props);
  }

  renderProperties() {
    const { 
      result, 
      location 
    } = this.props;

    const {
      currentLocaleKey: locale, 
      defaultLocaleKey: defaultLocale
    } = result;

    const { latitude, longitude, zoom } = this.state;

    return (
      <div className={`${theme3Styles.mainContent}`}>
        <div className={theme3Styles.wrapper}>
          <div className={`${theme3Styles.mapView}`}>
            <div className={`${theme3Styles.mapViewLeft}`} style={{ height: '700px' }}>
              {latitude && !isNaN(latitude) && longitude && !isNaN(longitude) && 
              <OpenStreetMap 
                listingType="property" 
                listings={this.state.searchedProperties} 
                latitude={parseFloat(latitude)} 
                longitude={parseFloat(longitude)} 
                zoom={zoom}
              />}
            </div>
            <div className={`${theme3Styles.mapViewRight}`}>
              <div className={`${theme3Styles.propHeader}`}>
                <div className={`${theme3Styles.propHeaderLeft}`}>
                  <h2 className={`${theme3Styles.pageTitle} ${theme3Styles.mb10}`}>
                    {translateStatic("properties", locale, defaultLocale)}
                  </h2>
                  <div className={`${theme3Styles.pageTitleBottom}`}>
                    <span className={`${theme3Styles.resultCount}`}>
                      {this.state.searchedProperties.length}{" "}
                      {translateStatic("results", locale, defaultLocale)}
                    </span>
                  </div>
                </div>
                <div className={`${theme3Styles.propHeaderRight}`}>
                  <select
                    className={`${theme3Styles.field}`}
                    value={this.state.sort}
                    onChange={this.handleSortChange}
                  >
                    <option value="">Sort</option>
                    {Object.keys(this.state.sortOptions).map((key) => {
                      return (
                        <option value={key} key={key}>
                          {this.state.sortOptions[key]}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className={`${theme3Styles.propScroll}`}>
                <div className={`${theme3Styles.propScrollCont}`}>
                  <PropertyList
                    properties={this.state.properties}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                  <Paginator
                    themeStyles={theme3Styles}
                    paginator={this.state.paginatorInfo}
                    prefix={this.state.navigationPrefix}
                    search={this.props.location.search}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {(() => {
          if (this.state.paginatorInfo.total == 0) {
            return (
              <div className={`${theme3Styles.noDataMsg}`}>
                <span>
                  {translateStatic("no_property_found", locale, defaultLocale)}
                </span>
              </div>
            );
          }
        })()}
      </div>
    );
  }

  render() {
    const { 
      result, 
      pageContext, 
      location
    } = this.props;

    const {
      allProperties, 
      propertyCategories, 
      propertyTypes
    } = pageContext;

    const {
      remarso: {
        domainByURL: {
          website: {
            templateConfiguration,
            template: { templateVariables },
          },
        },
      },
      currentLocaleKey: locale, 
      defaultLocaleKey: defaultLocale
    } = result;

    const metaTitle = templateVariableValue(
      templateConfiguration,
      templateVariables,
      "meta-title-properties",
      locale,
      defaultLocale
    );

    return (
      <Layout>
        <SEO metaTitle={metaTitle} />

        <div className={`${theme3Styles.listingHeaderC}`}>
          <div className={theme3Styles.wrapper}>
            <div className={`${theme3Styles.listingHeader}`}>
              <PropertySearch
                properties={allProperties}
                setProperties={this.setProperties}
                setMapOptions={this.setMapOptions}
                propertyCategories={propertyCategories}
                propertyTypes={propertyTypes}
                locale={locale}
                defaultLocale={defaultLocale}
                navigationPrefix={this.state.navigationPrefix}
                location={location}
              />
            </div>
          </div>
        </div>

        {this.renderProperties()}
      </Layout>
    );
  }
}

export default Theme3PropertiesMap;

Theme3PropertiesMap.propTypes = {
  pageContext: PropTypes.object.isRequired,
  result: PropTypes.object,
  location: PropTypes.object,
};
